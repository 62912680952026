$wrap: 800px;

.feature {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 40px 0;
}

.feature-image {
  flex-shrink: 0;
  width: 40%;
  aspect-ratio: 3 / 2;
  border-radius: var(--rounded);
  overflow: hidden;
  box-shadow: var(--shadow);
}

.feature-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.feature-text {
  flex-grow: 1;
}

.feature-title {
  font-size: var(--large);
  text-align: center;
  font-family: var(--heading);
  font-weight: var(--semi-bold);
}

.feature[data-flip] {
  flex-direction: row-reverse;
}

@media (max-width: $wrap) {
  .feature {
    flex-direction: column !important;
  }

  .feature-image {
    width: 100%;
    max-width: calc($wrap / 2);
  }
}
